import React from "react";
import Img from "gatsby-image"

const PageBuilderImage = ({image}) => {
	return (
		<div className="single-image">
			<Img fluid={image} imgStyle={{objectFit: 'contain'}} />
		</div>
	)
}

export default PageBuilderImage
