import React from "react";
import Img from "gatsby-image"
const BlockContent = require('@sanity/block-content-to-react')

const PageBuilderImageAndText = ({image, rawText}) => {
	return (
		<div className="image-and-text">
			<div>
				<Img fluid={image} imgStyle={{objectFit: 'contain'}}/>
			</div>
			<div className="my-4">
				<BlockContent
					blocks={rawText}
				/>
			</div>
		</div>
	)
}

export default PageBuilderImageAndText
