import React from "react";
const BlockContent = require('@sanity/block-content-to-react')

const PageBuilderText = ({rawText}) => {
	return (
		<div className="single-text">
			<BlockContent
				blocks={rawText}
			/>
		</div>
	)
}

export default PageBuilderText
