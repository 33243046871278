import React from "react";
import PageBuilderText from "./pageBuilderText"
import PageBuilderImage from "./pageBuilderImage"
import PageBuilderImageAndText from './pageBuilderImageAndText'

const ColumnsBuilder = ({columns}) => {
	const columnsCount = columns.length
	let columnsWidth = 'w-full px-4'

	if (columnsCount === 2) {
		columnsWidth = 'w-full sm:w-1/2 px-4'
	} else if (columnsCount === 3) {
		columnsWidth = 'w-full sm:w-1/2 lg:w-1/3 px-4'
	} else if (columnsCount > 3) {
		columnsWidth = 'w-full sm:w-1/2 lg:w-1/4 px-4'
	}


	return (
		<div className="flex flex-wrap -mx-4 my-2 mt-10">
			{
				columns.map(column => 
					<div key={column._key} className={`${columnsWidth} mb-4`}>
						{column._type === 'pageBuilderContent' &&
							<PageBuilderText rawText={column._rawText}/>
						}
						{column._type === 'pageBuilderImage' &&
							<PageBuilderImage image={column.image.asset.fluid}/>
						}
						{column._type === 'imageAndText' &&
							<PageBuilderImageAndText 
								image={column.image.asset.fluid}
								rawText={column._rawText}
								/>
						}
					</div>
				)
			}
		</div>
	)
	//columns.forEach(column => {
		//if (column._type === 'pageBuilderContent') {
			//content = <PageBuilderText rawText={column._rawText}/>
		//} else if 	
	//})

	//return content
}

export default ColumnsBuilder
