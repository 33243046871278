import React from "react"
import PageBuilderText from "./pageBuilderText"
import PageBuilderTitle from "./pageBuilderTitle"
import PageBuilderDivider from "./pageBuilderDivider"
import PageBuilderCenterText from "./pageBuilderCenterText"
import ColumnsBuilder from "./columnsBuilder"

const PageBuilder = ({pageBuilder}) =>  {
  const components = {
    pageBuilderColumns: ColumnsBuilder,
    pageBuilderTitle: PageBuilderTitle,
    pageBuilderContent: PageBuilderText,
    pageBuilderDivider: PageBuilderDivider,
		pageBuilderCenterText: PageBuilderCenterText
  }

  return pageBuilder.map((block, index) => {
		let componentProps = {}
	
		if (block._type === 'pageBuilderColumns') {
			componentProps = {
				columns: block.columns,
				key: block._key
			}
		} else if (block._type === 'pageBuilderTitle') {
			componentProps = {
				title: block.largeTitle,
				key: block._key
			}
		} else if (block._type === 'pageBuilderContent') {
			componentProps = {
				rawText: block._rawText,
				key: block._key
			}
		} else if (block._type === 'pageBuilderDivider') {
			componentProps = {
				key: block._key
			}
		} else if (block._type === 'pageBuilderCenterText') {
			componentProps = {
				key: block._key,
				text: block.centerText
			}
		}

    if (components[block._type]) {
			return React.createElement(components[block._type], componentProps)
    }
		else {
			return <div></div>
		}
  })
}

export default PageBuilder;
